import { useState } from 'react';
import { Link } from 'react-router-dom';

import { AuthService } from '../services/apiService';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await AuthService.login(username, password);
            const token = response.data.token;
            if (token) {
                localStorage.setItem('token', token);
                localStorage.setItem('username', response.data.username);
                window.location.href = "/";
            } else {
                setError(response.data.error);
            }
        } catch (e) {
            setError('Something went wrong. Please, try again.');
            console.error(e);
        }
    };

    return (
        <div className='d-flex justify-content-center align-items-center custom-height'>
            <div className='auth-form'>
                <h1 className="fw-bold">Log in</h1>
                <br/>
                <form onSubmit={handleLogin}>
                    <input
                        type="text"
                        placeholder="Email or username"
                        className='form-control'
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        className='form-control mt-3'
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <br/>
                    {error && (<p className='text-danger'>{error}</p>)}
                    <button type='submit' className="btn btn-primary mb-3">Sign In</button>
                </form>
                <Link to="/signup">Don't have an account? Sign Up</Link>
            </div>
        </div>
    );
}

export default Login;