function createCircularImage(file, callback) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = function () {
        const size = Math.min(img.width, img.height);
        canvas.width = size;
        canvas.height = size;

        ctx.save(); // Save the current state of the context
        ctx.beginPath();
        ctx.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.clip();

        // Draw a transparent rectangle to clear the canvas
        ctx.clearRect(0, 0, size, size);

        const x = (size - img.width) / 2;
        const y = (size - img.height) / 2;
        ctx.drawImage(img, x, y, img.width, img.height);

        ctx.restore(); // Restore the saved state (removes the clipping path)

        const circularImage = canvas.toDataURL(); // No need to specify image/jpeg if you want to preserve transparency
        const blob = dataURItoBlob(circularImage);
        const circularFile = new File([blob], file.name, { type: file.type });
        callback(circularFile);
    };

    img.src = URL.createObjectURL(file);

    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }
}

export { createCircularImage };