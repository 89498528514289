import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import '../styles/image.css';
import '../styles/ex.css';

import { ImageService } from '../services/apiService';

export default function Image() {
    const { name } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const baseUrl =
        process.env.REACT_APP_API_URL !== '' ?
            process.env.REACT_APP_API_URL
        :
            window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

    const [exist, setExist] = useState(false);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                await ImageService.view(name);
                setExist(true);
            } catch (e) {
                if (e.response && e.response.status === 404) {
                    queryParams.set('notfound', true);
                    const newSearch = `?${queryParams.toString()}`;
                    navigate({ pathname: '/', search: newSearch });
                    return;
                }

                toast.error('Something went wrong. Please, try again.');
                console.error(e);
            }
        };

        fetchImage();
    }, [navigate, name]);

    const handleCopy = (text) => {
        try {
            toast.success('Copied.', {
                autoClose: 1000
            });
            navigator.clipboard.writeText(text);
        } catch (e) {
            toast.error('Couldn\'t copy.');
            console.error(e);
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center custom-height">
            {exist && (
                <div className='pri image bg-darkblue'>
                    <center>
                        <img src={baseUrl + '/image/' + name} />
                    </center>
                    <div className="infos">
                        <div className='info'>
                            <p>Share</p>
                            <span>{window.location.href}</span>

                            <button className="btn btn-primary ms-2"
                                    onClick={() => handleCopy(window.location.href)}>Copy
                            </button>
                        </div>

                        <div className="info">
                            <p>Direct</p>
                            <span>{baseUrl + '/image/' + name}</span>

                            <button className="btn btn-primary ms-2"
                                    onClick={() => handleCopy(baseUrl + '/image/' + name)}>Copy
                            </button>
                        </div>

                        <div className="info">
                            <p>BBCode</p>
                            <span>[img]{baseUrl + '/image/' + name}[/img]</span>

                            <button className="btn btn-primary ms-2"
                                    onClick={() => handleCopy('[img]' + baseUrl + '/image/' + name + '[/img]')}>Copy
                            </button>
                        </div>

                        <div className="info">
                            <p>HTML</p>
                            <span>{'<img src="' + baseUrl + '/image/' + name + '" />'}</span>

                            <button className="btn btn-primary ms-2"
                                    onClick={() => handleCopy('<img src="' + baseUrl + '/image/' + name + '" />')}>Copy
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}