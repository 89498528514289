import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ImageService } from "../services/apiService";
import { createCircularImage } from "../utils/imageUtils";

import '../styles/animation.css';
import '../styles/home.css';

import UserAnimImg from '../assets/images/user-animated.gif';
import UserImg from '../assets/images/user.webp';

export default function Animation() {
    const navigate = useNavigate();

    const [color1, setColor1] = useState('#FF0000');
    const [color2, setColor2] = useState('#0000FF');
    const [uploading, setUploading] = useState(0);

    const handleColorChange1 = (e) => {
        setColor1(e.target.value);
    };

    const handleColorChange2 = (e) => {
        setColor2(e.target.value);
    };

    const handleUpload = async (e, type) => {
        if (uploading > 0) return;
        setUploading(type);

        const uploadedFile = e.target.files[0];
        if (!uploadedFile) return;

        try {
            let finalFile = uploadedFile;

            if (type === 1) {
                const response = await ImageService.animate(uploadedFile, color1, color2);
                if (response.error) {
                    throw new Error(response.error);
                } else {
                    const blob = new Blob([response.data], { type: 'image/gif' });
                    finalFile = new File([blob], 'animated.gif', { type: 'image/gif' });
                }
            } else if (type === 2) {
                finalFile = await new Promise((resolve) => {
                    createCircularImage(uploadedFile, function (circularFile) {
                        resolve(circularFile);
                    });
                });
            }

            const response = await ImageService.upload(finalFile);
            const name = response.data.name;
            if (name) {
                navigate(`/share/${name}`);
                toast.success('Your image has been uploaded successfully!', {
                    autoClose: 5000,
                });
            } else {
                throw new Error(response.data.error);
            }
        } catch (e) {
            const errorMessage = e.message || 'Something went wrong. Please, try again.';
            toast.error(errorMessage);
            console.error(e);
        } finally {
            setUploading(0);
        }
    };

    return (
        <div className='d-flex justify-content-center align-items-center custom-height'>
            <div className="wegjun mb-5">
                <div className="d-flex justify-content-center align-items-center">
                    <h2 className="fw-bold" style={{fontSize: '2.8rem'}}>Try out our profile effects!</h2>
                    <span className="badge rounded-5 d-none d-md-block ms-3">BETA</span>
                </div>
                <p className="d-block text-gray"
                   style={{marginTop: '-.5rem', fontFamily: 'YuGothic', fontWeight: 300, fontSize: '18px'}}>Simply drag
                    and drop your image and <span className="text-warning fw-bold"
                                                  style={{textShadow: '0px 0px 4px #ffc107'}}>flex</span> your profile
                    avatars!</p>
                <div style={{marginTop: '5rem'}}>
                    <div className="d-flex cont align-items-center gap-5 justify-content-center">
                        <div className="colors bg-cyan-trans rounded-4 py-2 px-3 d-flex" style={{gap: '.5rem'}}>
                            <input type="color" id="colorPicker1" className='picker' value={color1}
                                   onChange={handleColorChange1}/>
                            <input type="color" id="colorPicker2" className='picker' value={color2}
                                   onChange={handleColorChange2}/>
                        </div>

                        <div className='oix'>
                            <img src={UserAnimImg} alt="Default user avatar" style={{maxWidth: '225px'}}/>
                            <div className='mt-2'>
                                <input type="file" id="imageUpload" onChange={(e) => handleUpload(e, 1)}
                                       accept=".png,.jpg,.jpeg,.gif" hidden/>
                                <label htmlFor="imageUpload" className="upload-main fw-medium"
                                       style={{cursor: "pointer"}}>
                                    {uploading === 1 ? "Uploading..." : "Upload Image"}
                                </label>
                            </div>
                        </div>

                        <h5>OR</h5>

                        <div className='oix'>
                            <img src={UserImg} alt="Default user avatar" className="rounded-circle"
                                 style={{maxWidth: '225px'}}/>
                            <div className='mt-2'>
                                <input type="file" id="imageUpload2" onChange={(e) => handleUpload(e, 2)}
                                       accept=".png,.jpg,.jpeg,.gif" hidden/>
                                <label htmlFor="imageUpload2" className="upload-main fw-medium"
                                       style={{cursor: "pointer"}}>
                                    {uploading === 2 ? "Uploading..." : "Upload Image"}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
