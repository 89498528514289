import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.webp';

const Navbar = () => {
    const downloadLink = "https://mediafire.com/";

    const token = localStorage.getItem('token') ? localStorage.getItem('token') : null;

    let username;
    if (token) username = localStorage.getItem('username');

    const logOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        window.location.reload();
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-darkblue-trans">
            <div className="container-fluid ghij">
                <Link className="navbar-brand" to="/">
                    <img src={Logo} className='logo' alt='Picto logo'/>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse float-right" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link text-gray" to="/" style={{letterSpacing: '.6px'}}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-gray" to="/animation" style={{letterSpacing: '.6px'}}>Profile Effects</Link>
                        </li>
                    </ul>
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <a className="download btn btn-outline-warning fw-semibold" href={downloadLink} target="_blank" rel='noreferrer'>Download</a>
                        </li>

                        {token ? (
                            <li className="nav-item">
                                <button className="signup btn btn-primary" onClick={logOut}>Sign out from {username}</button>
                            </li>
                        ) : (
                            <>
                                <li className="nav-item">
                                    <Link className="signup btn btn-primary" to="/signup">Register</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="login btn btn-outline-primary text-white" to="/login">Login</Link>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;