import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import { ImageService } from '../services/apiService';

import UserImg from '../assets/images/user.webp';
import '../styles/home.css';

export default function Home() {
    const navigate = useNavigate();

    const [params, setParams] = useSearchParams();

    const [uploading, setUploading] = useState(false);
    const [makePublic, setMakePublic] = useState(false);

    useEffect(() => {
        const paramsSearchVal = params.get('notfound');
        if (paramsSearchVal === 'true') {
            toast.error('That image link does not exist, or was removed!');

            const newParams = new URLSearchParams(params);
            newParams.delete('notfound');
            setParams(newParams);
        }
    }, [params]);

    const handleUpload = async (e) => {
        const uploadedFile = e.target.files[0];
        if (!uploadedFile) return;

        setUploading(true);

        try {
            const response = await ImageService.upload(uploadedFile, makePublic);
            const name = response.data.name;
            if (name) {
                navigate(`/share/${name}`);
                toast.success('Your image has been uploaded successfully!', {
                    autoClose: 5000
                });
            } else {
                toast.error(response.data.error);
            }
        } catch (e) {
            toast.error('Something went wrong. Please, try again.');
            console.error(e);
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className='d-flex justify-content-center align-items-center custom-height'>
            <div className='homer'>
                <h2 className='fw-bold'>Upload and share your images <u style={{textDecorationColor: '#02abdb'}}>effortlessly.</u></h2>
                <p8 className='d-block text-gray' style={{marginTop:'-.5rem', fontFamily: 'YuGothic', fontWeight: 300, fontSize: '18px'}}>Simply drag and drop your images to begin uploading!</p8>
                <br/>
                <input type="file" id="imageUpload" onChange={handleUpload} accept=".png,.jpg,.jpeg,.gif" hidden />
                {!uploading ? (
                    <label htmlFor="imageUpload" className="upload-main fw-medium" id='up' style={{cursor: "pointer"}}>Upload Image</label>
                ) : (
                    <label className="upload-main fw-medium" id='up'>Uploading...</label>
                )}
                <br/><br/>

                {localStorage.getItem('token') && (
                    <div style={{marginTop:'-.5rem'}}>
                        <input type='checkbox' className='form-check-input rounded-circle' id='visible' onChange={(e) => setMakePublic(e.target.checked)}/>
                        <label htmlFor='visible' className='ms-2' style={{marginTop: '.1rem'}}>Make your image public</label>
                    </div>
                )}

                <br/>
                <div className='show-ex'>
                    <h4 className='ex1 fw-bold'>Try profile picture effects!</h4>
                    <p className='text-gray' style={{marginTop:'-.5rem', fontFamily: 'YuGothic', fontWeight: 300}}>Circular and animated borders!</p>
                    <Link className='ex-a fw-medium' to='/animation'>Try now</Link>
                    <br/><br/>
                    <div>
                        <Link to="/animation">
                            <img src={UserImg} className='ex2 ef1' alt='Default user avatar' />
                        </Link>
                        <FaArrowLeft style={{color: "white"}}/>
                        <Link to="/animation">
                            <img src={UserImg} className='ex2' alt='Default user avatar' />
                        </Link>
                        <FaArrowRight style={{color: "white"}}/>
                        <Link to="/animation">
                            <img src={UserImg} className='ex2 ef2' alt='Default user avatar'></img>
                        </Link>
                    </div>
                </div>
                <br/><br/>
            </div>
        </div>
    );
}