import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ImageService } from '../services/apiService';

const Footer = () => {
    const [ count, setCount ] = useState(0);

    useEffect(() => {
        ImageService.count().then(count => {
            setCount(count);
        }).catch(error => {
            console.error(error);
        });
    }, []);

    return (
        <footer className="bg-darkblue pt-4">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-6 col-12">
                        <div className="text-start">
                            <Link className='text-white text-decoration-none fw-medium' to='/terms'>Terms of Service</Link>
                            <br />
                            <Link className='text-white text-decoration-none fw-medium' to='/privacy'>Privacy Policy</Link>
                            <br />
                            <Link className='text-white text-decoration-none fw-medium' to='/faq'>F.A.Q</Link>
                        </div>
                    </div>

                    <div className="col-md-6 col-12">
                        <div className="float-end">
                            <br/>
                            <div className='d-flex'>
                                <div className='foot-stats fw-bold px-4 py-1'>
                                    Uploaded
                                    <br/>
                                    <span className="text-cyan fs-5">{count}</span>
                                </div>
                                <div className='foot-stats fw-bold px-4 py-1 ms-4'>
                                    Screenshots
                                    <br/>
                                    <span className="text-cyan fs-5">0</span>
                                </div>
                            </div>
                            <p style={{color: '#2c437f'}} className="text-end mt-4">&copy; Picto. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;