import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './App.css';

// Pages
import Layout from "./pages/Layout";

import Home from './pages/Home';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Image from './pages/Image';
import Animation from './pages/Animation';
import Public from './pages/Public';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Faq from './pages/Faq';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="login" element={<Login />} />
          <Route path="share/:name" element={<Image />} />
          <Route path="animation" element={<Animation />} />
          <Route path="public" element={<Public />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="faq" element={<Faq />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;