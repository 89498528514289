import '../styles/ex.css';

function Terms() {
    return (
        <div className='d-flex justify-content-center align-items-center custom-height'>
            <div className='pri bg-darkblue'>
                <h2 className="fw-bold">Terms of Service</h2>
                <br />
                <p>
                    <b>By accessing and using our services, you agree to abide by the following Terms of Service.</b>
                    <br /><br />
                    <h4 >Service Usage</h4>
                    Our service may not be utilized to transmit any unlawful content. Users are solely responsible for all content hosted via our platform.
                    <br /><br />
                    <h4>Files</h4>
                    Files failing to meet our terms of service criteria will be promptly deleted, and depending on severity, may result in permanent user bans. Users bear full responsibility for content hosted on our service. We reserve the right to delete any file without prior notice.
                    <br /><br />
                    <h4>Restrictions</h4>
                    Users are prohibited from uploading pornography, offensive content, illegal material, inappropriate images, or content violating copyrights. Commercial use is permitted, excluding spamming or other illicit activities.
                    <br /><br />
                    <h4>Accounts</h4>
                    User accounts are the exclusive responsibility of their owners. We enforce robust security measures to safeguard user files. We reserve the right to delete any account without prior notice.
                    <br /><br />
                    <h4>Restrictions</h4>
                    Do not use our service to upload material that could be considered harassment.<br />
                    Do not upload any inappropriate images, such a pornography, abuse, or violence.<br />
                    Do not upload files that are illegal.<br />
                    Do not upload copyrighted images.<br />
                    <br /><br />
                    <h4>Copyright</h4>
                    In cases of copyright or DMCA issues, users will be permanently banned. Contact copyright@picto.gg to report any file that infringes your copyright.
                    <br />
                    Contact our team:<br />
                    <a href="mailto:help@picto.gg">help@picto.gg</a>
                </p>
            </div>
        </div>
    );
}

export default Terms;