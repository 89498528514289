import { useState } from 'react';
import { Link } from 'react-router-dom';

import { AuthService } from '../services/apiService';

function SignUp() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword]= useState('');
    const [error, setError] = useState('');

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const response = await AuthService.register(username, email, password);
            const token = response.data.token;
            if (token) {
                localStorage.setItem('token', token);
                localStorage.setItem('username', username);
                window.location.href = "/";
            } else {
                setError(response.data.error);
            }
        } catch (e) {
            setError('Something went wrong. Please, try again.');
            console.error(e);
        }
    };

    return (
        <div className='d-flex justify-content-center align-items-center custom-height'>
            <div className='auth-form'>
                <h1 className="fw-bold">Register</h1>
                <br/>
                <form onSubmit={handleRegister}>
                    <input
                        type="text"
                        placeholder="Username"
                        className='form-control'
                        onChange={(e) => setUsername(e.target.value)}
                        minLength={3}
                        maxLength={24}
                        required
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        className='form-control mt-3'
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        className='form-control mt-3'
                        onChange={(e) => setPassword(e.target.value)}
                        minLength={6}
                        required
                    />
                    <br/>
                    {error && (<p className='text-danger'>{error}</p>)}
                    <button type="submit" className="btn btn-primary mb-3">Sign Up</button>
                </form>
                <Link to="/login">Already have an account? Login</Link>
            </div>
        </div>
    );
}

export default SignUp;