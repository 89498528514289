import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const AuthService = {
    login: async (username, password) => {
        try {
            return await axios.post(`${API_URL}/auth/login`, { name: username, password });
        } catch (e) {
            if (e.response && e.response.data.error) return e.response;
            throw e;
        }
    },

    register: async (username, email, password) => {
        try {
            return await axios.post(`${API_URL}/auth/register`, { name: username, email, password });
        } catch (e) {
            if (e.response && e.response.data.error) return e.response;
            throw e;
        }
    }
};

const ImageService = {
    count: async () => {
        try {
            const response = await axios.get(`${API_URL}/image/count`);
            return response.data.count;
        } catch (e) {
            throw e;
        }
    },

    upload: async (file, visible = false) => {
        try {
            const token = localStorage.getItem('token') ? localStorage.getItem('token') : null;

            const formData = new FormData();
            formData.append('file', file);
            if (token && visible) formData.append('visible', true);

            return await axios.post(`${API_URL}/image/upload`, formData, {
                headers: {
                    ...(token && {'Authorization': `Bearer ${token}`}),
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (e) {
            if (e.response && e.response.data.error) return e.response;
            throw e;
        }
    },

    view: async (name) => {
        try {
            return await axios.get(`${API_URL}/image/${name}?no-redirect=true`);
        } catch (e) {
            throw e;
        }
    },

    animate: async (file, color1, color2) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('color1', color1);
            formData.append('color2', color2);

            return await axios.post(`${API_URL}/image/animate`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'blob'
            });
        } catch (e) {
            const responseText = await e.response.data.text();
            const responseJson = JSON.parse(responseText);
            if (responseJson && responseJson.error) return responseJson;
            throw e;
        }
    }
};

export { AuthService, ImageService };